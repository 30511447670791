const messages = {
    'en': {
        "participants": "Participants",
        "participant": "Participant",
        "add participant": "Add Participant",
        "name": "Name",
        "team": "Team/Club",
        "no_participants": "No participants",
        "action": "ACTION",
        "date": "Date",
        "category": "Category/Class",
        "english": "English",
        "indonesian": "Bahasa Indonesia",
        "create_bracket": "Create Bracket",
        "back": "Back",
        "random": "Random",
        "stop": "Stop",
        "update": "Update",
        "add": "Add",
        "cancel editing": "Cancel Editing",
        "options": "options",
        "tournament name": "Tournament Name",
        "category name": "Category/Class",
        "advanced": "Advanced",
        "tournament date": "Tournament Date",
        "bracket type": "Bracket Type",
        "name only": "Name Only",
        "name & team": "Name & Team/Club",
        "tournament type": "Tournament Type",
        "competition area": "Competition Area",
        "show match number": "Show Match Number",
        "download csv sample": "Download CSV Sample",
        "sample csv": "CSV Sample",
        "language": "Language",
        "Download PDF Bracket": "Download Bracket (PDF)",
    },
    'id': {
        "participants": "Peserta",
        "participant": "Peserta",
        "add participant": "Tambah Peserta",
        "name": "Nama",
        "team": "Tim/Klub",
        "no_participants": "Tidak ada peserta",
        "action": "AKSI",
        "date": "Tanggal",
        "category": "Kategori/Kelas",
        "english": "Bahasa Inggris",
        "indonesian": "Bahasa Indonesia",
        "create_bracket": "Buat Bagan Pertandingan",
        "back": "Kembali",
        "random": "Random",
        "stop": "Stop",
        "update": "Perbarui",
        "add": "Tambah",
        "cancel editing": "Batalkan Editing",
        "options": "Pengaturan",
        "tournament name": "Nama Tournament",
        "category name": "Nama Kategori/Kelas",
        "advanced": "Pengaturan Lanjutan",
        "tournament date": "Tanggal Tournament",
        "bracket type": "Tipe Bagan",
        "name only": "Hanya Nama",
        "name & team": "Nama & Tim/Klub",
        "tournament type": "Tipe Tournament",
        "competition area": "Area/Lapangan",
        "show match number": "Tampilkan Nomor Bagan",
        "download csv sample": "Download contoh CSV",
        "sample csv": "Contoh CSV",
        "language": "Bahasa",
        "Download PDF Bracket": "Download Bagan (PDF)",
    }
}

export default messages