
export const dateNow = (slash = '/') => {
    let d = new Date()
    let date = d.getFullYear() + slash + ('0'+ (d.getMonth()+1)).slice(-2) + slash + ('0' + d.getDate()).slice(-2)
    return date
}

export const arrayMaxId = (arr) => {
    let len = arr.length
    let max = -Infinity
    while (len--) {
        if(arr[len].id > max) {
            max = arr[len].id
        }
    }
    return max
}

export const arrayMaxOrder = (arr) => {
    let len = arr.length
    let max = -Infinity
    while (len--) {
        if(arr[len].order > max) {
            max = arr[len].order
        }
    }
    return max
}

export const fullScreen = () => {
    var elem = document.documentElement;
    // isFullScreen.value = true
    if (elem.requestFullScreen) {
          elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
      }
    return true
  }
export const closeFullScreen = () => {
    // isFullScreen.value = false
    var elem = document.documentElement;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }

    return false
  }

export const currentSettings = (settings) => {
    if(localStorage.getItem('tournament-settings')) {
      try {
        let settings = JSON.parse(localStorage.getItem('tournament-settings'))
        // localSettings.localStorage = settings
        return settings
      }
      catch(e) {
        localStorage.removeItem('tournament-settings');
        localStorage.setItem('tournament-settings', JSON.stringify({lang: 'en'}))
      }
    }else {
      localStorage.setItem('tournament-settings', JSON.stringify({lang: 'en'}))
    }
    return settings
  }

  export const changeLang = (e) => {
    localStorage.setItem('tournament-settings', JSON.stringify({lang: e.target.value}))
  }

